import { typedEntries, mapToObject } from "../utils/objects";

const STORAGE_KEY_USED_IN_THIS_CONTEXT = "RECLAIM_USED_IN_THIS_CONTEXT";
const STORAGE_KEY_WARNED_OF_USAGE = "RECLAIM_WARNED_OF_USAGE";
const SHADOW_STORAGE_KEY_API_KEY = "RECLAIM_SHADOW_API_KEY";
const SHADOW_STORAGE_KEY_TRACKING_CODE = "RECLAIM_SHADOW_TRACKING_CODE";

const SHADOW_QS_NAME_TRACKING_CODE = "shadowTrackingCode";
const SHADOW_QS_NAME_API_KEY = "shadowApiKey";

let shadowApiKey: string | undefined;
let shadowTrackingCode: string | undefined;
let isImpersonating = false;

/**
 * This is all to remove the shadow/impersonation stuff
 * from the query string.  Dropping it in an anonymous
 * function to make it easier to visually parse out, and
 * to avoid collision with the many common variable
 * names it uses.
 */
(() => {
  if (typeof window !== "object") return;

  const warnedOfUsage = !!sessionStorage.getItem(STORAGE_KEY_WARNED_OF_USAGE);
  const usedInThisContext = !!localStorage.getItem(STORAGE_KEY_USED_IN_THIS_CONTEXT);
  if (!usedInThisContext) localStorage.setItem(STORAGE_KEY_USED_IN_THIS_CONTEXT, JSON.stringify(true));

  const url = new URL(window.location.href);
  // get the query string as an object
  const qObj = mapToObject(url.search.replace(/^\?/, "").split("&"), (pair) => {
    const [key, val] = pair.split("=");
    return [key, decodeURIComponent(val)];
  });

  if (qObj[SHADOW_QS_NAME_TRACKING_CODE]) {
    shadowApiKey = qObj[SHADOW_QS_NAME_API_KEY];
    shadowTrackingCode = qObj[SHADOW_QS_NAME_TRACKING_CODE];
  } else {
    shadowApiKey = sessionStorage.getItem(SHADOW_STORAGE_KEY_API_KEY) || undefined;
    shadowTrackingCode = sessionStorage.getItem(SHADOW_STORAGE_KEY_TRACKING_CODE) || undefined;
  }

  // remove the shadow props from the URL
  delete qObj[SHADOW_QS_NAME_TRACKING_CODE];
  delete qObj[SHADOW_QS_NAME_API_KEY];

  // get the remaining query string
  const qArr = typedEntries(qObj)
    .map(([key, val]) => key && val && `${key}=${val}`)
    .filter((i) => !!i);

  // reattach it to the URL
  url.search = qArr.length ? "?" + qArr.join("&") : "";

  //set the URL back in the browser
  void window.history.replaceState(null, "", url);

  if (shadowTrackingCode) {
    if (usedInThisContext && !warnedOfUsage) {
      const confirmed = confirm(
        `It looks like you've logged into Reclaim in this browser scope before.  We HIGHLY recommend that you only impersonate in a private or incognito window.\n\nClick "OK" to continue with impersonation.  Click "Cancel" to continue without impersonation.`
      );
      sessionStorage.setItem(STORAGE_KEY_WARNED_OF_USAGE, JSON.stringify(true));
      if (!confirmed) return;
    }

    if (!shadowApiKey) {
      shadowApiKey = prompt("Paste in your API Key") || undefined;
    }
  }

  // store the props
  if (shadowApiKey && shadowTrackingCode) {
    isImpersonating = true;
    sessionStorage.setItem(SHADOW_STORAGE_KEY_API_KEY, shadowApiKey);
    sessionStorage.setItem(SHADOW_STORAGE_KEY_TRACKING_CODE, shadowTrackingCode);
  }
})();

export { isImpersonating, shadowApiKey, shadowTrackingCode };
