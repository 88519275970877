import { PopperPlacementType, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import {
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuestsActions, useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { QuestOrb, QuestOrbProps } from "./QuestOrb";
import { ActiveQuest, GenericQuestStepConfig, QuestConfig, QuestGroup } from "./quests.types";
import { getQuestsStepConfig } from "./quests.util";
import { QuestStepPopper } from "./QuestStepPopper";
import { QUESTS_CONFIG } from "./quests.util";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
    },
    fullWidth: {
      width: "100%",
    },
  }),
  {
    classNamePrefix: "QuestStepWrapper",
  }
);

export type QuestStepWrapperJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestStepWrapperProps<QG extends QuestGroup> = {
  classes?: Partial<ClassNameMap<QuestStepWrapperJSSClassKey>>;
  className?: string;
  config: ActiveQuest<QG> | ActiveQuest<QG>[];
  hidden?: boolean;
  disabled?: boolean;
  QuestOrbProps?: QuestOrbProps;
  popperPlacement?: PopperPlacementType;
  fullWidth?: boolean;
  children?: ReactNode;
};

export const QuestStepWrapper = forwardRef<HTMLSpanElement, QuestStepWrapperProps<QuestGroup>>(
  (
    {
      className,
      classes: extClasses,
      config,
      hidden,
      disabled,
      QuestOrbProps,
      popperPlacement,
      fullWidth,
      children,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const orbRef = useRef<HTMLDivElement | null>(null);
    const innerRef = useRef<HTMLSpanElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLSpanElement);

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("sm"));

    const { activeQuest } = useQuestsState();
    const { onStepComplete, setActiveQuest } = useQuestsActions();

    const [popperWasDisplayed, setPopperWasDisplayed] = useState(false);
    const [popperActive, setPopperActive] = useState(false);

    const activeConfig = useMemo<ActiveQuest<QuestGroup> | undefined>(() => {
      if (activeQuest) {
        const cfg = Array.isArray(config) ? config : [config];
        return cfg.find(
          ({ group, quest, step }) =>
            activeQuest.group === group && activeQuest.quest === quest && activeQuest.step === step
        );
      }
    }, [config, activeQuest]);

    const stepConfig = useMemo<GenericQuestStepConfig | undefined>(
      () => (!activeConfig || !activeQuest ? undefined : getQuestsStepConfig(activeQuest)),
      [activeQuest, activeConfig]
    );

    const lastStepId = useMemo<string | undefined>(() => {
      // find the id of the last step of the active quest
      if (!!activeConfig) {
        return (QUESTS_CONFIG[activeConfig.group].quests as QuestConfig<QuestGroup>[])
          .find((quest) => quest.id === activeConfig.quest)
          ?.steps?.slice(-1)
          .pop()?.id;
      } else return undefined;
    }, [activeConfig]);

    const orbActive = !!(activeConfig && !hidden && stepConfig) && !!stepConfig;

    const handleClose = useCallbackSafeRef(() => {
      if (!activeConfig || !stepConfig) return;

      if (stepConfig.type === "orb") {
        onStepComplete(activeConfig.step);
      }

      // If they close the last step end the quest
      if (lastStepId === activeConfig.step) setActiveQuest(undefined);

      setPopperActive(false);
    });

    const showStepPopover = useCallbackSafeRef(() => {
      if (!stepConfig?.popoverConfig || popperActive) return;

      setPopperActive(true);
      setPopperWasDisplayed(true);
    });

    const handleClick = useCallbackSafeRef<MouseEventHandler>((e) => {
      if (!stepConfig || !activeConfig || disabled) return;

      if (xs && stepConfig.type !== "action") {
        showStepPopover();
      } else if (stepConfig.type === "action") {
        onStepComplete(activeConfig.step);
      }
    });

    /**
     * Show popover if not shown yet and orb becomes active
     */
    useEffect(() => {
      let timer;

      if (orbActive && !popperWasDisplayed) {
        timer = setTimeout(() => {
          showStepPopover();
        }, 750);
      }

      return () => {
        clearTimeout(timer);
      };
    }, [orbActive, popperWasDisplayed, showStepPopover]);

    return (
      <>
        <span
          ref={innerRef}
          className={clsx(classes.root, className, { [classes.fullWidth]: fullWidth })}
          onClick={handleClick}
          {...rest}
        >
          {children}
          {orbActive && stepConfig.type !== "action" && (
            <QuestOrb
              ref={orbRef}
              type={stepConfig.type}
              onMouseEnter={showStepPopover}
              disabled={disabled}
              {...QuestOrbProps}
            />
          )}
        </span>
        {!!stepConfig && !hidden && (
          <QuestStepPopper
            type={stepConfig.type}
            open={popperActive}
            stepConfig={stepConfig}
            onClose={handleClose}
            anchorEl={stepConfig.type === "action" ? innerRef.current : orbRef.current}
            placement={popperPlacement}
          />
        )}
      </>
    );
  }
);
