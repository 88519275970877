import { Box } from "@mui/material";
import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import { MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import moduleClasses from "./Tooltip.module.scss";

export type TooltipCSSClassKey = keyof typeof moduleClasses;
export type TooltipTitleFnHelpers = { closeTooltip: () => void };
export type TooltipTitleFn = (helpers: TooltipTitleFnHelpers) => NonNullable<ReactNode>;
export type TooltipTitle = NonNullable<ReactNode> | TooltipTitleFn;
export type TooltipChildren<WITH_SPAN> = WITH_SPAN extends true ? ReactNode : ReactElement;

export type TooltipProps<WITH_SPAN extends boolean> = Omit<
  MuiTooltipProps,
  "classes" | "title" | "disableInteractive" | "children"
> & {
  className?: string;
  classes?: SCSSClasses<TooltipCSSClassKey>;
  TooltipClasses?: MuiTooltipProps["classes"];
  disabled?: boolean;
  disableTooltip?: boolean;
  letEventsPropagate?: boolean;
  title: TooltipTitle;
  closeOnClick?: boolean;
  interactive?: boolean;
  withSpan?: WITH_SPAN;
  children: TooltipChildren<WITH_SPAN>;
};

export const Tooltip = <WITH_SPAN extends boolean>({
  className,
  classes: extClasses,
  children,
  disabled,
  TooltipClasses,
  title,
  letEventsPropagate,
  closeOnClick,
  withSpan,
  interactive,
  ...rest
}: TooltipProps<WITH_SPAN>): ReactElement<TooltipProps<WITH_SPAN>> => {
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  const [open, setOpen] = useState(false);

  const openTooltip = useCallbackSafeRef(() => setOpen(true));
  const closeTooltip = useCallbackSafeRef(() => setOpen(false));

  const stopProp = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => !letEventsPropagate && e.stopPropagation(),
    [letEventsPropagate]
  );

  useEffect(() => {
    window.addEventListener("blur", closeTooltip);
    return () => window.removeEventListener("blur", closeTooltip);
  }, [closeTooltip]);

  return (
    <MuiTooltip
      className={clsx(className, classes.root)}
      disableInteractive={!interactive}
      open={open}
      onOpen={openTooltip}
      onClose={closeTooltip}
      PopperProps={{
        onClick: (e) => {
          e.stopPropagation();
          if (closeOnClick) closeTooltip();
        },
      }}
      title={
        <Box className={classes.titleWrapper} onMouseDown={stopProp} onMouseUp={stopProp}>
          {typeof title === "function" ? title({ closeTooltip }) : title}
        </Box>
      }
      classes={{
        ...TooltipClasses,
        tooltip: clsx(classes.tooltip, TooltipClasses?.tooltip, {
          [classes["tooltip--disabled"]]: disabled,
        }),
      }}
      arrow
      {...rest}
    >
      {(withSpan ? <span className={classes.innerSpan}>{children}</span> : children) as ReactElement}
    </MuiTooltip>
  );
};
