import { concat, filter, fromArray, fromPromise, map, merge, pipe } from "wonka";
import { deserialize, upsert } from "../utils/wonka";
import {
  RecurringAssignmentAttendeeStatus as RecurringAssignmentAttendeeStatusDto,
  RecurringOneOnOne as RecurringOneOnOneDto,
  SubscriptionType as SubscriptionTypeDto,
} from "./client";
import { dtoToOneOnOne, OneOnOneToDto } from "./OneOnOnes.mutators";
import {
  InviteeEligibility,
  OneOnOne,
  RecurringAssignmentAttendeeStatus,
  RecurringOneOnOneStatus,
} from "./OneOnOnes.types";
import { NotificationKeyStatus, TransformDomain } from "./types";

export class OneOnOnesDomain extends TransformDomain<OneOnOne, RecurringOneOnOneDto> {
  resource = "OneOnOne";
  cacheKey = "oneOnOne";
  pk = "id";

  public serialize = OneOnOneToDto;
  public deserialize = dtoToOneOnOne;

  watchWs$$ = (instances?: boolean) =>
    pipe(
      this.ws?.subscription$$({
        subscriptionType: SubscriptionTypeDto.OneOnOne,
        instances: !!instances, // FIXME (IW): Need to add this on the backend
      }) || fromArray([]),
      filter((envelope) => !!envelope.data),
      map((envelope) => envelope.data),
      deserialize(this.deserialize)
    );

  watchWs$ = this.watchWs$$();

  watchAll$ = pipe(
    merge([this.upsert$, this.watchWs$]),
    map((items) => this.patchExpectedChanges(items))
  );

  watch$$ = (instances?: boolean) =>
    pipe(
      merge([this.upsert$, this.watchWs$$(instances)]),
      map((items) => this.patchExpectedChanges(items))
    );

  list$$ = (instances?: boolean) =>
    pipe(
      fromPromise(Promise.all([this.list(instances), this.invites(instances)])),
      map(([list, invites]) => this.patchExpectedChanges([...list, ...invites]))
    );

  listAndWatch$$ = (instances?: boolean) => {
    return pipe(
      concat<OneOnOne[] | OneOnOne>([this.list$$(instances), this.watch$$(instances)]),
      upsert((e) => this.getPk(e)),
      map((items) => [...items])
    );
  };

  watchId$$ = (id: number) => {
    return pipe(
      this.watchAll$,
      map((items) => items?.find((i) => i.id === id))
    );
  };

  list = this.manageErrors(
    this.deserializeResponse((instances?: boolean) => this.api.oneOnOne.getOneOnOnes({ instances }))
  );

  get = this.deserializeResponse((id: number, instances?: boolean) => this.api.oneOnOne.getOneOnOne(id, { instances }));

  create = this.manageErrors(
    this.deserializeResponse((oneOnOne: Omit<OneOnOne, "id">, sendTeamInvite?: boolean) =>
      this.api.oneOnOne.createOnOnOne(this.serialize(oneOnOne) as RecurringOneOnOneDto, { sendTeamInvite })
    )
  );

  patch = this.manageErrors(
    this.deserializeResponse(async (id: number, patch: Partial<OneOnOne>) => {
      const notificationKey = this.generateUid("patch", id);

      this.expectChange(notificationKey, id, patch, true);

      return this.api.oneOnOne
        .patchOneOnOne(id, this.serialize(patch), { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  delete = this.manageErrors((id: number) => {
    const notificationKey = this.generateUid("delete", id);

    this.expectChange(notificationKey, id, { deleted: true });

    return this.api.oneOnOne
      .deleteOneOnOne(id, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  invites = this.manageErrors(
    this.deserializeResponse((instances?: boolean) => this.api.oneOnOne.getMeetingInvites({ instances }))
  );

  inviteWithErrors = this.deserializeResponse((id: number, instances?: boolean, inviteKey?: string) =>
    this.api.oneOnOne.getMeetingInvite(id, { instances, inviteKey })
  );

  respondAnonymously = this.deserializeResponse(
    (id: number, inviteKey: string, status: RecurringAssignmentAttendeeStatus) =>
      this.api.oneOnOne.respondAnonymously(id, inviteKey, {
        status: status && RecurringAssignmentAttendeeStatusDto[status],
      })
  );

  invite = this.manageErrors(this.inviteWithErrors);

  suggestions = this.manageErrors(this.deserializeResponse(this.api.oneOnOne.getSuggestions));

  respond = this.manageErrors((id: number, accept: boolean) => {
    const status = accept ? RecurringAssignmentAttendeeStatus.Accepted : RecurringAssignmentAttendeeStatus.Declined;
    const notificationKey = this.generateUid("respond", id);

    this.expectChange(notificationKey, id, {
      status: accept ? RecurringOneOnOneStatus.Accepted : RecurringOneOnOneStatus.Declined,
    });

    return this.api.oneOnOne
      .respond(id, { status: status as unknown as RecurringAssignmentAttendeeStatusDto }, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  getInviteeEligibility = (email: string): Promise<InviteeEligibility> => {
    return this.api.oneOnOne.getInviteeEligibility({ email }).catch((cause) => {
      console.warn("Error: Invalid email address: " + email);
      throw cause;
    });
  };

  convertPendingToAuto = this.deserializeResponse((id: number, title: string) =>
    this.api.oneOnOne.convertPendingToAuto(id, { title })
  );
}
