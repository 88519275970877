import { Button, Dialog, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import { VFC } from "react";
import { LogWorkForm, LogWorkFormProps } from "../forms/LogWorkForm";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    paper: {
      borderRadius: theme.shape.borderRadius * 3,
      maxWidth: 460,
      padding: theme.spacing(3.5, 3),
      backgroundColor: theme.colors.white,
    },
    closeBtn: {
      position: "absolute",
      zIndex: 1,
      top: theme.spacing(0.25),
      right: theme.spacing(0.25),
      margin: "0 0 0 auto",
      minWidth: 0,
    },
    dialogTitle: {
      padding: theme.spacing(0, 0, 2),
      wordBreak: "break-word",
      overflowWrap: "anywhere",
    },
  }),
  {
    classNamePrefix: "LogWorkDialog",
  }
);

export type LogWorkDialogJSSClassKey = keyof ReturnType<typeof useStyles>;

export type LogWorkDialogProps = LogWorkFormProps & {
  classes?: Partial<ClassNameMap<LogWorkDialogJSSClassKey>>;
  className?: string;
  open: boolean;
  taskTitle?: string;
};

export const LogWorkDialog: VFC<LogWorkDialogProps> = ({
  className,
  classes: extClasses,
  open,
  taskTitle,
  onCancel,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Dialog
      className={clsx(classes.root, className)}
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onCancel}
      fullWidth
    >
      <Button onClick={onCancel} className={classes.closeBtn} color="primary" variant="text">
        <CloseRoundedIcon fontSize="small" />
      </Button>
      <DialogTitle className={classes.dialogTitle}>{taskTitle ? `Log work for: ${taskTitle}` : "Log work"}</DialogTitle>
      <LogWorkForm onCancel={onCancel} {...rest} />
    </Dialog>
  );
};
