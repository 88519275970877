import { DependencyList, useEffect, useState } from "react";
import { useCallbackSafeRef } from "./useCallbackSafeRef";
import { useCaptureError } from "./useCaptureError";
import { usePromisedDebounce } from "./useDebounce";
import { useIsMountedRef } from "./useIsMountedRef";

export type UseMemoizedPromiseReturnType<T> = [
  response: T | undefined,
  loading: boolean,
  error: Error | undefined,
  forceReload: () => Promise<T | undefined>,
];

export type UseMemoizedPromiseOptions = {};

export const useMemoizedPromise = <T>(
  factory: () => Promise<T>,
  deps: DependencyList,
  options: UseMemoizedPromiseOptions = {}
): UseMemoizedPromiseReturnType<T> => {
  const [val, setVal] = useState<T>();
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const { captureError } = useCaptureError();

  useEffect(() => {
    if (error)
      captureError(error, {
        severity: "error",
      });
  }, [error, captureError]);

  const forceReload = useCallbackSafeRef(async () => {
    try {
      const r = await factory();
      if (!isMountedRef.current) return;
      setVal(r);
      return r;
    } catch (cause) {
      const e = new Error("Problem while re-running promise", { cause });
      setError(e);
    }
  });

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    void (async () => {
      try {
        const p = factory();
        // offset starting loading by a frame so
        // already-resolved promises don't get
        // picked up
        timer = setTimeout(() => setLoading(true));
        const r = await p;
        if (!isMountedRef.current) return;
        setVal(r);
        setError(undefined);
      } catch (cause) {
        setError(new Error("Problem while running promise", { cause }));
      } finally {
        if (!isMountedRef.current) return;
        if (timer) clearTimeout(timer);
        setLoading(false);
      }
    })();
    return () => timer && clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [val, loading, error, forceReload];
};

export const useDebouncedMemoizedPromise = <T>(
  factory: () => Promise<T>,
  deps: DependencyList,
  debounceMs: number
): UseMemoizedPromiseReturnType<T | undefined> => {
  const cb = usePromisedDebounce(factory, debounceMs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemoizedPromise(cb, deps);
};
