// platform checks
const macos = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
const windows = ["Win32", "Win64", "Windows", "WinCE"];
const ios = ["iPhone", "iPad", "iPod"];

// user agent checks
const mobile = ["Mobile", "Mobile Safari", "IEMobile", "webOS", "Blackberry", "Opera Mini", "Opera Mobi"];

export enum OS {
  MacOS,
  Windows,
  Linux,
  iOS,
  Android,
  Unknown,
}

export class Browser {
  private static instance: Browser;

  static getInstance() {
    if (!Browser.instance) Browser.instance = new Browser();
    return Browser.instance;
  }

  static isBrowser() {
    return typeof window !== "undefined";
  }

  get isBrowser() {
    return Browser.isBrowser();
  }

  get window() {
    return window;
  }

  get userAgent() {
    return window.navigator.userAgent;
  }

  get platform() {
    return window.navigator.platform;
  }

  get orientation() {
    return window.orientation;
  }

  get isDesktop() {
    return this.isBrowser && !mobile.some((i) => this.userAgent.includes(i));
  }

  get isMobile() {
    return this.isBrowser && mobile.some((i) => this.userAgent.includes(i));
  }
}

export class Platform {
  private static instance: Platform;

  static getInstance() {
    if (!Platform.instance) Platform.instance = new Platform();
    return Platform.instance;
  }

  private browser = new Browser();

  get isMac() {
    return this.browser.isBrowser && macos.includes(this.browser.platform);
  }

  get isWin() {
    return this.browser.isBrowser && windows.includes(this.browser.platform);
  }

  get isLinux() {
    return this.browser.isBrowser && /Linux/.test(this.browser.platform);
  }

  get isIos() {
    return this.browser.isBrowser && ios.includes(this.browser.platform);
  }

  get isAndroid() {
    return this.browser.isBrowser && /Android/.test(this.browser.userAgent);
  }

  get os() {
    if (this.isMac) return OS.MacOS;
    if (this.isWin) return OS.Windows;
    if (this.isIos) return OS.iOS;
    if (this.isAndroid) return OS.Android;
    if (this.isLinux) return OS.Linux;
    return OS.Unknown;
  }

  get controlKey() {
    return [OS.MacOS, OS.iOS].includes(this.os) ? "⌘" : "Ctrl";
  }
}

export const browser = Browser.getInstance;
export const platform = Platform.getInstance;
