/* eslint-disable no-restricted-imports */
import { Button, ButtonGroup, Drawer, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import CodeIcon from "@mui/icons-material/CodeRounded";
import clsx from "clsx";
import { useState, VFC } from "react";
import { DevActionList } from "../dev/components/DevActionList";
import { useDevApi } from "../dev/dev.client.utils";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { AsyncButton } from "./forms/AsyncButton";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    paper: {
      display: "flex",
      flexDirection: "column",
    },
    actions: {
      flex: "0 0 auto",
    },
    btnWrapper: {
      position: "fixed",
      top: theme.spacing(),
      left: theme.spacing(),
      transition: theme.transitions.create("transform"),
      zIndex: 999999,
    },
    btnWrapperOpen: {
      transform: "translateY(-100%)",
    },
    btn: {
      padding: `${theme.spacing()}px !important`,
      minWidth: "auto !important",
      opacity: 0.5,
      transition: theme.transitions.create("opacity"),
      "&:hover": {
        opacity: 1,
      },
    },
    reconciles: {
      alignSelf: "center",
      flex: "1 1 auto",
    },
  }),
  {
    classNamePrefix: "DevDrawer",
  }
);

export type DevDrawerJSSClassKey = keyof ReturnType<typeof useStyles>;

export type DevDrawerProps = {
  classes?: Partial<ClassNameMap<DevDrawerJSSClassKey>>;
  className?: string;
};

export const DevDrawer: VFC<DevDrawerProps> = ({ className, classes: extClasses }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  const { reconcileTeam, reconcileUser, reconcilePlans } = useDevApi();

  /********************/
  /*     useState     */
  /********************/

  const [open, setOpen] = useState(false);

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  const closeMe = useCallbackSafeRef(() => setOpen(false));
  const openMe = useCallbackSafeRef(() => setOpen(true));

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  if (process.env.NODE_ENV !== "development") return <></>;

  return (
    <>
      <Drawer
        className={clsx(classes.root, className)}
        anchor="left"
        open={open}
        onClose={closeMe}
        classes={{ paper: classes.paper }}
      >
        <DevActionList className={classes.actions} />
        <div className={classes.reconciles}>
          <Typography variant="h5">Reconcile:</Typography>
          <ButtonGroup>
            <AsyncButton onClick={reconcileTeam}>Team</AsyncButton>
            <AsyncButton onClick={reconcileUser}>User</AsyncButton>
            <AsyncButton onClick={reconcilePlans}>Plans</AsyncButton>
          </ButtonGroup>
        </div>
      </Drawer>
      <div className={clsx(classes.btnWrapper, { [classes.btnWrapperOpen]: open })}>
        <Button className={classes.btn} onClick={openMe} color="primary" variant="contained" size="small">
          <CodeIcon />
        </Button>
      </div>
    </>
  );
};
