import { debounce, DebouncedFunc, DebounceSettings } from "lodash";
import { useEffect, useMemo } from "react";
import { promisedDebounce, PromisedDebounceCb, PromisedDebounceFn } from "../utils/debounce";
import { useCallbackSafeRef } from "./useCallbackSafeRef";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any[]) => any>(
  callback: T,
  waitMs?: number,
  options?: DebounceSettings
): DebouncedFunc<T> => {
  // Break the options down to its primitive parts.
  // This way options passed in directly as `{}`
  // don't trigger the `useMemo` on every frame.
  // const staticOptions = useMemo<DebounceSettings>(
  //   () => ({
  //     leading: options?.leading,
  //     maxWait: options?.maxWait,
  //     trailing: options?.trailing,
  //   }),
  //   [options?.leading, options?.maxWait, options?.trailing]
  // );

  // use useMemo instead of useCallback so we can evaluate debounce ONLY when deps change
  const debounced = useMemo<DebouncedFunc<T>>(() => debounce(callback, waitMs, options), [callback, waitMs, options]);

  // Kill callbacks on unmount
  useEffect(() => {
    return () => debounced.cancel();
  }, [debounced]);

  return debounced;
};

export const usePromisedDebounce = <T>(callback: PromisedDebounceCb<T>, waitMs: number): PromisedDebounceFn<T> => {
  const safe = useCallbackSafeRef(callback);
  // use useMemo instead of useCallback so we can evaluate debounce ONLY when deps change
  const debounced = useMemo(() => promisedDebounce(safe, waitMs), [safe, waitMs]);

  useEffect(() => {
    return () => debounced.cancel();
  }, [debounced]);

  return debounced;
};
