import { filterFalsy } from "./arrays";
import { typedEntries } from "./objects";

export const mergeClassNames = <T extends { [cn: string]: string }>(
  baseClasses: Readonly<T>,
  ...classeses: Readonly<Partial<T>>[]
): T =>
  classeses.reduce<T>(
    (map, classes) => {
      typedEntries(classes).forEach(
        ([jsName, className]) => (map[jsName] = filterFalsy([map[jsName], className]).join(" ") as T[keyof T])
      );
      return map;
    },
    { ...baseClasses }
  );
