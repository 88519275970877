import { isAfter } from "date-fns";
import { filter, fromArray, map, pipe } from "wonka";
import { sourceT } from "wonka/dist/types/src/Wonka_types.gen";
import { dateToYyyyMmDd } from "../utils/dates";
import { Envelope } from "./adaptors/ws";
import { AnalyticsWSResultsDto } from "./Analytics.dtoAdditions";
import {
  analyticsGroupingIntervalToDto,
  analyticssMetricToDto,
  dtoToAnalyticsMetrics,
  dtoToAnalyticsWSMetrics
} from "./Analytics.mutators";
import { AnalyticsGroupingInterval, AnalyticsMetricName, AnalyticsResults } from "./Analytics.types";
import {
  AnalyticsGroupingInterval as AnalyticsGroupingIntervalDto,
  AnalyticsMetricName as AnalyticsMetricNameDto, SubscriptionType as SubscriptionTypeDto
} from "./client";
import { TransformDomain } from "./types";

export type AnalyticsGetterOptions = { groupingInterval?: AnalyticsGroupingInterval; start: Date; end?: Date };
export type AnalyticsConnectorOptions = AnalyticsGetterOptions & { notificationKey?: string };

const prepStatGetterRequest = <METRICS extends AnalyticsMetricName[]>(
  metrics: METRICS,
  options: AnalyticsGetterOptions
): {
  start: string;
  end: string;
  metricName: AnalyticsMetricNameDto[];
  groupingInterval?: AnalyticsGroupingIntervalDto;
} => {
  const { groupingInterval, start, end = new Date() } = options;
  if (isAfter(start, end)) throw new Error("End cannot come after start");

  return {
    start: dateToYyyyMmDd(start),
    end: dateToYyyyMmDd(end),
    metricName: metrics.map(analyticssMetricToDto),
    groupingInterval: analyticsGroupingIntervalToDto(groupingInterval || "DAILY"),
  };
};

const Subscription = {
  subscriptionType: SubscriptionTypeDto.Analytics,
};

export class AnalyticsDomain extends TransformDomain<unknown, unknown> {
  resource = "Analytics";
  cacheKey = "analytics";

  teamWs$ = pipe(
    (this.ws?.subscription$$(Subscription) as sourceT<Envelope<AnalyticsWSResultsDto>>) || fromArray([]),
    map((envelope) => ({
      ...envelope,
      data: dtoToAnalyticsWSMetrics(envelope.data),
    }))
  );

  watchTeam$$ = () => this.teamWs$;

  userWs$ = pipe(
    (this.ws?.subscription$$(Subscription) as sourceT<Envelope<AnalyticsWSResultsDto>>) || fromArray([]),
    filter((envelope) => !!envelope.data),
    map((envelope) => dtoToAnalyticsWSMetrics(envelope.data))
  );

  watchUser$$ = () => this.userWs$;

  getTeamAnalytics = this.typedManageErrors(
    async <METRICS extends AnalyticsMetricName[]>(
      metrics: METRICS,
      options: AnalyticsGetterOptions
    ): Promise<AnalyticsResults<METRICS>> => {
      return dtoToAnalyticsMetrics(
        await this.api.analytics.analyticsTeamAnalytics(prepStatGetterRequest(metrics, options))
      );
    }
  );

  getUserAnalytics = this.typedManageErrors(
    async <METRICS extends AnalyticsMetricName[]>(
      metrics: METRICS,
      options: AnalyticsGetterOptions
    ): Promise<AnalyticsResults<METRICS>> => {
      return dtoToAnalyticsMetrics(
        await this.api.analytics.analyticsUserAnalytics(prepStatGetterRequest(metrics, options))
      );
    }
  );

  connectToTeamAnalytics = this.typedManageErrors(
    async <METRICS extends AnalyticsMetricName[]>(
      metrics: METRICS,
      options: AnalyticsConnectorOptions
    ): Promise<string> => {
      const { notificationKey = this.generateUid("TeamAnalytics"), ...getterOptions } = options;

      await this.api.analytics.analyticsTeamAnalyticsV2({
        notificationKey,
        ...prepStatGetterRequest(metrics, getterOptions),
      });

      return notificationKey;
    }
  );

  connectToUserAnalytics = this.typedManageErrors(
    async <METRICS extends AnalyticsMetricName[]>(
      metrics: METRICS,
      options: AnalyticsConnectorOptions
    ): Promise<string> => {
      const { notificationKey = this.generateUid("UserAnalytics"), ...getterOptions } = options;

      await this.api.analytics.analyticsUserAnalyticsV2({
        notificationKey,
        ...prepStatGetterRequest(metrics, getterOptions),
      });

      return notificationKey;
    }
  );
}
