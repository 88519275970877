import { ZoomUser as ZoomUserDto } from "./client";
import { ZoomUser } from "./OneOnOnes.types";
import { dateToStr, strToDate } from "../utils/dates";
import { DayOfWeek } from "./Calendars";
import {
  AttendeeTimePolicyView as AttendeeTimePolicyDto,
  DayOfWeek as DayOfWeekDto,
  Recurrence as RecurrenceDto,
  RecurringAssignmentInstance as RecurringAssignmentInstanceDto,
  RecurringOneOnOne as RecurringOneOnOneDto,
  TimePolicyType as TimePolicyTypeDto,
} from "./client";
import { EventStatus } from "./Events";
import { dtoToTimePolicy, timePolicyToDto } from "./TimeSchemes.mutators";
import { nullable } from "./types";
import { UserTimezone } from "./Users";
import {
  AttendeeTimePolicy,
  ConferenceType,
  OneOnOne,
  Recurrence,
  RecurringAssignmentInstance,
  RecurringOneOnOneStatus,
} from "./OneOnOnes.types";
import { dtoToPriorityLevel, priorityLevelToDto } from "./Users.mutators";

export const dtoToZoomUser = (dto: ZoomUserDto): ZoomUser => ({ ...dto });

export const attendeeTimePolicyToDto = (tp: AttendeeTimePolicy): AttendeeTimePolicyDto => ({
  ...tp,
  timePolicy: timePolicyToDto(tp.timePolicy),
  timePolicyInViewerTimezone: timePolicyToDto(tp.timePolicyInViewerTimezone),
});

export const dtoToAttendeeTimePolicy = (dto: AttendeeTimePolicyDto): AttendeeTimePolicy => ({
  ...dto,
  timePolicy: dtoToTimePolicy(dto.timePolicy),
  timePolicyInViewerTimezone: dtoToTimePolicy(dto.timePolicyInViewerTimezone),
  // swagger incorrectly types this object
  timezone: dto.timezone as UserTimezone,
});

export function dtoToRecurringAssignmentInstance(dto: RecurringAssignmentInstanceDto): RecurringAssignmentInstance {
  return {
    ...dto,
    eventKey: dto.eventKey as unknown as string,
    eventStatus: dto.eventStatus as unknown as EventStatus,
    start: strToDate(dto.start),
    end: strToDate(dto.end),
  };
}

export function dtoToOneOnOne(dto: RecurringOneOnOneDto): OneOnOne {
  return {
    ...dto,
    id: (!!dto.id ? dto.id : undefined) as unknown as number, // strip 0 ids (long id == 0),
    idealDay: dto.idealDay as unknown as DayOfWeek,
    snoozeUntil: nullable(dto.snoozeUntil, strToDate),
    status: dto.status as unknown as RecurringOneOnOneStatus,
    instances: dto.instances?.map(dtoToRecurringAssignmentInstance),
    updated: strToDate(dto.updated),
    recurrence: Recurrence.get(dto.recurrence) || Recurrence.Weekly,
    conferenceType: (dto.conferenceType as unknown as ConferenceType) || ConferenceType.None,
    organizersTimeZone: dto.organizersTimeZone!,
    inviteeTimePolicy: dtoToAttendeeTimePolicy(dto.inviteeTimePolicy),
    organizerTimePolicy: dtoToAttendeeTimePolicy(dto.organizerTimePolicy),
    effectiveTimePolicy: dtoToTimePolicy(dto.effectiveTimePolicy),
    organizer: dto.organizer || undefined,
    organizerTimeSchemeId: dto.organizerTimeSchemeId || undefined,
    organizerTimePolicyType: dto.organizerTimePolicyType || undefined,
    organizerOneOffPolicy: dto.organizerOneOffPolicy && dtoToTimePolicy(dto.organizerOneOffPolicy),
    inviteeTimePolicyType: dto.inviteeTimePolicyType || undefined,
    inviteeOneOffPolicy: dto.inviteeOneOffPolicy && dtoToTimePolicy(dto.inviteeOneOffPolicy),
    priority: dtoToPriorityLevel(dto.priority),
  };
}

export function OneOnOneToDto(oneOnOne: Partial<OneOnOne>): Partial<RecurringOneOnOneDto> {
  const dto: Partial<RecurringOneOnOneDto> = {
    ...oneOnOne,
    idealDay: oneOnOne.idealDay as unknown as DayOfWeekDto,
    snoozeUntil: nullable(oneOnOne.snoozeUntil, dateToStr),
    recurrence: oneOnOne.recurrence?.toJSON() as RecurrenceDto,
    invitee: oneOnOne.invitee as unknown as RecurringOneOnOneDto["invitee"],
    status: oneOnOne.status as unknown as RecurringOneOnOneDto["status"],
    instances: oneOnOne.instances as unknown as RecurringAssignmentInstanceDto[],
    updated: dateToStr(oneOnOne.updated),
    conferenceType: (oneOnOne.conferenceType === ConferenceType.None
      ? null
      : oneOnOne.conferenceType) as unknown as RecurringOneOnOneDto["conferenceType"],
    organizersTimeZone: oneOnOne.organizersTimeZone,
    organizerTimePolicy: oneOnOne.organizerTimePolicy && attendeeTimePolicyToDto(oneOnOne.organizerTimePolicy),
    inviteeTimePolicy: oneOnOne.inviteeTimePolicy && attendeeTimePolicyToDto(oneOnOne.inviteeTimePolicy),
    effectiveTimePolicy: oneOnOne.effectiveTimePolicy && timePolicyToDto(oneOnOne.effectiveTimePolicy),

    organizerTimePolicyType: oneOnOne.organizerTimePolicyType as unknown as TimePolicyTypeDto,
    organizerOneOffPolicy: oneOnOne.organizerOneOffPolicy && timePolicyToDto(oneOnOne.organizerOneOffPolicy),

    inviteeTimePolicyType: oneOnOne.inviteeTimePolicyType as unknown as TimePolicyTypeDto,
    inviteeOneOffPolicy: oneOnOne.inviteeOneOffPolicy && timePolicyToDto(oneOnOne.inviteeOneOffPolicy),

    title: oneOnOne.title === "" ? (null as unknown as string) : oneOnOne.title, // temp fix until we create seperate types and dtos for patch
    priority: oneOnOne.priority ? priorityLevelToDto(oneOnOne.priority) : undefined,
  };

  // TODO: (SS) This can be removed when we only send changed values in the patch.
  if ((dto.conferenceType as unknown as ConferenceType) === ConferenceType.Custom) {
    delete dto.conferenceType;
  }

  return dto;
}
