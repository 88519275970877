import { Checkbox, FormControlLabel, List, ListItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect, useState, VFC } from "react";
import { Loading } from "../../components/Loading";
import { useUserContext } from "../../context/UserContext";
import { useTeamState } from "../../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { useMemoizedPromise } from "../../hooks/useMemoizedPromise";
import { useDevApi } from "../dev.client.utils";
import { DevConfig, DevRegetData } from "../dev.types";
import {
  DevActionListItem,
  DevActionListItemChangeHandler,
  DevActionListitemOpenChangeHandler,
  DevActionListitemRunHandler,
} from "./DevActionListItem";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 400,
      maxWidth: 600,
      padding: theme.spacing(3),
    },
  }),
  {
    classNamePrefix: "DevActionList",
  }
);

const allowReloadAtom = atomWithStorage("dev.actionList.allowReload", true);
const openActionsAtom = atomWithStorage<Record<string, boolean>>("dev.actionList.openActions", {});

export type DevActionListJSSClassKey = keyof ReturnType<typeof useStyles>;

export type DevActionListProps = {
  classes?: Partial<ClassNameMap<DevActionListJSSClassKey>>;
  className?: string;
};

export const DevActionList: VFC<DevActionListProps> = ({ className, classes: extClasses }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  const { runAction } = useDevApi();
  const [{ user }] = useUserContext();
  const { team } = useTeamState();
  const [config, loading, error] = useMemoizedPromise(
    async () =>
      (user?.id &&
        team.id &&
        ((await fetch(`/api/dev/getConfig?userId=${user.id}&teamId=${team.id}`).then((r) =>
          r.json()
        )) as DevConfig<"client">)) ||
      undefined,
    [team.id, user?.id]
  );

  /********************/
  /*     useState     */
  /********************/

  const [data, setData] = useState<DevRegetData>({});
  const [allowReload, setAllowReload] = useAtom(allowReloadAtom);
  const [openActions, setOpenActions] = useAtom(openActionsAtom);

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  const handleItemDataChange = useCallbackSafeRef<DevActionListItemChangeHandler<string, Record<string, unknown>>>(
    (key, itemData) => {
      setData({ ...data, [key]: itemData });
    }
  );

  const handleRun = useCallbackSafeRef<DevActionListitemRunHandler<string>>(async (key) => {
    const regetData = await runAction(key, data[key]);

    setData({
      ...data,
      ...regetData,
    });

    if (allowReload && config?.actions[key].reloadAfter) window.location.reload();
  });

  const toggleAllowReload = useCallbackSafeRef(() => {
    setAllowReload(!allowReload);
  });

  const actionOpenChangeHandler = useCallbackSafeRef<DevActionListitemOpenChangeHandler<string>>((key, open) =>
    setOpenActions({ ...openActions, [key]: open })
  );

  /********************/
  /*    useEffects    */
  /********************/

  useEffect(() => {
    if (!config) return;

    setData(
      config.actionOrder.reduce(
        (actionData, actionKey) => {
          actionData[actionKey] = {};
          const { args } = config.actions[actionKey];

          (args || []).forEach((arg) => {
            if (arg.default !== undefined) actionData[actionKey][arg.key] = arg.default;
          });

          return actionData;
        },
        {} as typeof data
      )
    );
  }, [config]);

  /********************/
  /*       JSX        */
  /********************/

  return (
    <div className={clsx(classes.root, className)}>
      <FormControlLabel
        control={<Checkbox onChange={toggleAllowReload} checked={allowReload} />}
        label="Allow reloads"
      />
      {(() => {
        if (error) return <code>{error.message}</code>;
        else if (loading || !config) return <Loading />;
        else {
          if (config.hasMissingEnvVars)
            return (
              <>
                <Typography>
                  Could not load dev tools, the following variables were missing from the environment (hint: you can add
                  them to <code>/.env.development.local</code>).
                </Typography>
                <List>
                  {config.missingEnvVars.map((varName) => (
                    <ListItem key={varName}>
                      <code>{varName}</code>
                    </ListItem>
                  ))}
                </List>
              </>
            );
          else
            return (
              <List>
                {config.actionOrder.map((key) => (
                  <DevActionListItem
                    key={key}
                    allowReload={allowReload}
                    action={config.actions[key]}
                    data={data[key]}
                    open={openActions[key]}
                    onOpenChange={actionOpenChangeHandler}
                    onDataChange={handleItemDataChange}
                    onRun={handleRun}
                  />
                ))}
              </List>
            );
        }
      })()}
    </div>
  );
};
