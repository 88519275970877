import { Override } from "../types/index";
import { browser } from "../utils/platform";
import { getAccountScopes } from "./Accounts.mutators";
import { PermissionLevel } from "./Accounts.types";
import { Credential as CredentialDto } from "./client";
import { TransformDomain } from "./types";

export enum AuthMode {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Expand = "EXPAND",
  Repair = "REPAIR",
  Tasks = "TASKS",
  ConnectCalendar = "DIRECT_CONNECT_PRIMARY",
}

export type Credential = Override<
  CredentialDto,
  {
    id: number;
    data?: {
      primary?: boolean;
      [key: string]: any;
    };
    permissions: PermissionLevel;
  }
>;

export function dtoToCredential(dto: CredentialDto): Credential {
  const c: Credential = { ...(dto as any) };

  const scopes = c.scopes?.split(/\s+/) || [];
  const expanded = getAccountScopes(true);
  const restricted = getAccountScopes();
  // for ui dev, flip cred logic ....
  // c.permissions = !expanded.some((s) => !scopes.includes(s)) ? PermissionLevel.Restricted : PermissionLevel.Expanded;
  c.permissions = !expanded.some((s) => !scopes.includes(s))
    ? "Expanded"
    : restricted.some((s) => scopes.includes(s))
    ? "Restricted"
    : "NotEnough";

  return c;
}

export function credentialToDto(credential: Partial<Credential>): CredentialDto {
  delete credential.permissions;
  return credential;
}

export class CredentialsDomain extends TransformDomain<Credential, CredentialDto> {
  resource = "Credential";
  cacheKey = "credentials";
  pk = "id";

  public deserialize = dtoToCredential;
  public serialize = credentialToDto;

  list = this.deserializeResponse(this.api.credentials.list1);

  listWithPrimary = this.deserializeResponse((includeInvalid?: boolean) =>
    this.api.credentials.list1({ includeInvalid })
  );

  get = this.deserializeResponse(this.api.credentials.get);

  getPrimary = this.deserializeResponse(this.api.credentials.getPrimary1);

  getPersonal = this.deserializeResponse(this.api.credentials.listPersonal);

  delete = async (credential: Credential) => this.api.credentials.delete2(credential.id);

  save(mode: AuthMode, state?: any) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    this.redirect("new", mode.toLowerCase(), undefined, state);
  }

  expand(credentialId: number, mode: AuthMode = AuthMode.Expand, state?: any) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    this.redirect("expand", credentialId, mode, state);
  }

  redirect(action: string, context: string | number, mode?: AuthMode, state?: any) {
    if (!browser().isBrowser) return;

    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    const authUrl = new URL(`${this.api.baseUrl}/credentials/auth/${action}/${context}`, window.location.href);
    if (mode) authUrl.searchParams.append("mode", mode);
    authUrl.searchParams.append("state", JSON.stringify({ redirect: window.location.pathname, ...(state || {}) }));
    window.location.href = authUrl.toString();
  }
}
