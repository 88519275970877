import { Override } from "../types";
import {
  AbstractLinearTeam as AbstractLinearTeamDto,
  AsanaIntegration as AsanaIntegrationDto,
  GoogleAddOnSettings,
  JiraIntegration as JiraIntegrationDto,
  JiraIntegrationPatch as JiraIntegrationPatchDto,
  JiraProject as JiraProjectDto,
  LinearIntegration as LinearIntegrationDto,
  RequestParams,
  TimeSchemeIntegrationRuleServiceType as TimeSchemeIntegrationRuleServiceTypeDto,
} from "./client";
import { EasyResponseTypeCaster } from "./domainTypeHelpers";
import {
  dtoToClickUpIntegrationDetails,
  dtoToIntegrationRule,
  dtoToIntegrationsEnabledMap,
  dtoToTodoistIntegrationDetails,
  integrationRuleCreateRequestToDto,
  integrationRuleReindexRequestToDto,
  integrationRuleUpdateRequestToDto,
} from "./Integrations.mutators";
import {
  TimeSchemeIntegrationRuleCreateRequest,
  TimeSchemeIntegrationRuleReindexRequest,
  TimeSchemeIntegrationRuleServiceType,
  TimeSchemeIntegrationRuleUpdateRequest,
} from "./Integrations.types";
import { dtoToZoomUser } from "./OneOnOnes.mutators";
import { Domain } from "./types";

const OAUTH_BASE_URI = process.env.NEXT_PUBLIC_OAUTH_BASE_URI;

/* Linear */

export type LinearTeam = Override<
  AbstractLinearTeamDto,
  {
    readonly id: string;
    readonly key: string;
    readonly name: string;
  }
>;

export type LinearIntegration = Override<
  LinearIntegrationDto,
  {
    readonly id: string;
    readonly availableTeams?: LinearTeam[];
  }
>;

export type AsanaIntegration = Override<AsanaIntegrationDto, {}>;

/* Jira */

export type JiraProject = Override<
  JiraProjectDto,
  {
    project_key: string;
    project_name: string;
  }
>;

export type JiraIntegration = Override<
  JiraIntegrationDto,
  {
    readonly id: string;
    available_projects?: JiraProject[];
  }
>;

export type JiraIntegrationPatch = Override<
  JiraIntegrationPatchDto,
  {
    installation: string;
    selected_project_keys: string[];
  }
>;

export class IntegrationsDomain extends Domain {
  /* Zoom */

  getZoomIntegration = this.typedManageErrors(async () =>
    dtoToZoomUser(await this.api.integrations.getZoomIntegration())
  );

  getZoomEnabled = this.manageErrors(() => {
    return this.api.integrations
      .getZoomIntegration()
      .then((zoom) => !!zoom && !!zoom.id)
      .catch((error) => {
        if (error.status === 404) {
          return false;
        } else {
          throw error;
        }
      });
  }) as EasyResponseTypeCaster<boolean>;

  /* Linear */

  getLinearIntegrations = this.manageErrors(this.api.linear.integrations4);

  patchLinearIntegration = (id: string, data: Partial<LinearIntegration>) => {
    return this.api.linear.patchIntegration2(id, data);
  };

  deleteLinearIntegration = (id: string) => {
    return this.api.linear.deleteIntegration3(id);
  };

  getLinearEnabled = this.manageErrors(() => {
    return this.api.linear
      .integrations4()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);
  });

  // Rules for Todoist / Clickup

  listRules = this.typedManageErrors(async (serviceType: TimeSchemeIntegrationRuleServiceType) => {
    const dto = await this.api.timescheme.listRules({
      type: serviceType as TimeSchemeIntegrationRuleServiceTypeDto,
    });
    return dto.map(dtoToIntegrationRule);
  });

  createRule = this.typedManageErrors(async (rule: TimeSchemeIntegrationRuleCreateRequest) => {
    const created = await this.api.timescheme.createRule(integrationRuleCreateRequestToDto(rule));
    return dtoToIntegrationRule(created);
  });

  updateRule = this.typedManageErrors(async (ruleId: string, rule: TimeSchemeIntegrationRuleUpdateRequest) => {
    const updated = await this.api.timescheme.updateRule(ruleId, integrationRuleUpdateRequestToDto(rule));
    return dtoToIntegrationRule(updated);
  });

  deleteRule = this.typedManageErrors(async (ruleId: string) => await this.api.timescheme.deleteRule(ruleId));

  reindexRule = this.typedManageErrors(async (ruleId: string, data: TimeSchemeIntegrationRuleReindexRequest) => {
    await this.api.timescheme.reindex1(ruleId, integrationRuleReindexRequestToDto(data));
  });

  /* Todoist */

  getTodoistIntegrationDetails = this.typedManageErrors(async () =>
    (await this.api.todoist.integrationsDetails()).map(dtoToTodoistIntegrationDetails)
  );

  deleteTodoistIntegration = (id: string) => {
    return this.api.todoist.deleteIntegration4(id);
  };

  getTodoistEnabled = this.typedManageErrors(() => {
    return this.api.todoist
      .integrations5()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);
  });

  /* Asana */

  getAsanaIntegrations: (params?: RequestParams) => Promise<AsanaIntegration[]> = this.manageErrors(
    this.api.asana.integrations
  );

  deleteAsanaIntegration = () => {
    return this.api.asana.deleteIntegration();
  };

  getAsanaEnabled = this.manageErrors(() => {
    return this.api.asana.integrations().then((list) => !!list?.length);
  });

  /* ClickUp */

  getClickupIntegrationDetails = this.typedManageErrors(async () =>
    (await this.api.clickup.clickUpIntegrationsDetails()).map(dtoToClickUpIntegrationDetails)
  );

  deleteClickUpIntegration = () => {
    return this.api.clickup.deleteIntegration1();
  };

  getClickUpEnabled = this.manageErrors(() => {
    return this.api.clickup
      .integrations1()
      .then((list) => !!list?.length && list.some((i) => !!i.workspace?.id))
      .catch(() => false);
  });

  /* Jira */

  getJiraIntegrations = this.manageErrors(this.api.jira.integrations3);

  patchJiraIntegration = (id: string, patch: JiraIntegrationPatch) => this.api.jira.patchIntegration1(id, patch);

  deleteJiraIntegration = (id: string) =>
    this.api.jira.deleteIntegration2(id).then((response: JiraIntegrationDto[]) => response as JiraIntegration[]);

  getJiraEnabled = () =>
    this.getJiraIntegrations()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);

  /* Google Add-on */

  getGoogleAddOnIntegrations: () => Promise<GoogleAddOnSettings[]> = this.manageErrors(
    this.api.googleAddOn.integrations2
  );

  getGoogleAddOnEnabled = () =>
    this.getGoogleAddOnIntegrations()
      .then((list) => !!list?.length && list.some((i) => !!i.enabled))
      .catch(() => false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authRedirect(redirectUri: string, state: any, newTab?: boolean) {
    const url = new URL(`${OAUTH_BASE_URI}${redirectUri}`, window.location.href);
    url.searchParams.append("state", JSON.stringify({ redirect: window.location.pathname, ...(state || {}) }));

    if (newTab) {
      return window.open(url.toString()) || undefined;
    } else {
      window.location.href = url.toString();
      return window;
    }
  }

  getEnabledMap = this.typedManageErrors(async () =>
    dtoToIntegrationsEnabledMap(await this.api.integrations.enabled())
  );
}
