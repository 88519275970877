import { Resources as ResourcesDto } from "./client";
import { dtoToResources } from "./Resources.mutators";
import { Resources } from "./Resources.types";
import { TransformDomain } from "./types";

export class ResourcesDomain extends TransformDomain<Resources, ResourcesDto> {
  resource = "Resources";
  cacheKey = "resources";

  public deserialize = dtoToResources;

  get = this.typedManageErrors(this.deserializeResponse(this.api.resources.getResources));
}