import { ReactElement, ReactNode } from "react";
import { Tooltip, TooltipChildren, TooltipProps, TooltipTitle } from "./Tooltip";

export interface ReasonTipReason {
  test: boolean;
  message: TooltipTitle;
}

export function evaluateReason(reasons: ReasonTipReason[]): [boolean, ReactNode] {
  const index = reasons.findIndex(({ test }) => test);
  if (index === -1) return [false, undefined];
  else return [true, reasons[index].message];
}

export type ReasonTipChildrenFn<WITHOUT_SPAN extends boolean> = (
  hasReason: boolean,
  message: ReactNode
) => TooltipChildren<WITHOUT_SPAN extends true ? false : true>;
export type ReasonTipChildren<WITHOUT_SPAN extends boolean> =
  | TooltipChildren<WITHOUT_SPAN extends true ? false : true>
  | ReasonTipChildrenFn<WITHOUT_SPAN>;

export type ReasonTipProps<WITHOUT_SPAN extends boolean> = Omit<
  TooltipProps<WITHOUT_SPAN extends true ? false : true>,
  "children" | "title" | "withSpan"
> & {
  reasons: ReasonTipReason[];
  appendReasonToTitle?: boolean;
  title?: TooltipProps<WITHOUT_SPAN extends true ? false : true>["title"];
  children?: ReasonTipChildren<WITHOUT_SPAN>;
  childrenOverride?: ReasonTipChildren<WITHOUT_SPAN>;
  noSpan?: WITHOUT_SPAN;
};

export const ReasonTip = <WITHOUT_SPAN extends boolean>({
  reasons,
  children,
  childrenOverride,
  appendReasonToTitle,
  title,
  noSpan,
  ...rest
}: ReasonTipProps<WITHOUT_SPAN>): ReactElement<ReasonTipProps<WITHOUT_SPAN>> => {
  if (!children && !childrenOverride) throw new Error("Must provide either children or childrenOverride");

  const [hasReason, message] = evaluateReason(reasons);
  let tt: ReactNode;

  if (hasReason) tt = message;
  if (appendReasonToTitle && title && tt) tt = `${title} ${message}`;
  if (!tt) tt = title;

  children = childrenOverride || children;
  children = typeof children === "function" ? children(hasReason, message) : children;

  return (
    <Tooltip
      title={tt || false}
      disableHoverListener={!tt}
      disableTouchListener={!tt}
      disableFocusListener={!tt}
      enterDelay={0}
      disabled={!hasReason && !title}
      withSpan={!noSpan}
      {...rest}
    >
      {children as ReactElement}
    </Tooltip>
  );
};
