import { SeverityLevel } from "@sentry/nextjs";
import { atom, useAtomValue } from "jotai";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { Primitive } from "../types";
import { MILLISECONDS_PER_HOUR } from "../utils/dates";
import { useCallbackSafeRef } from "./useCallbackSafeRef";
import * as Sentry from "@sentry/nextjs";

type ErrorHandlerProvider = "SENTRY" | "UNKNOWN";

const DEFAULT_ERROR_HANDLER_PROVIDER: ErrorHandlerProvider = "SENTRY";

type ErrorSeverity = SeverityLevel;

type ErrorTags = {
  [key: string]: Primitive;
};

interface CaptureErrorOptions {
  severity?: ErrorSeverity;
  tags?: ErrorTags;
  statusCode?: number;
}

interface ErrorImplementation {
  implementation: (sentry: typeof Sentry | undefined, error: unknown, options: CaptureErrorOptions) => void;
}

const SentryErrorProviderImplementation: ErrorImplementation = {
  implementation: (sentry, error, options = {}) => {
    if (!sentry) return;
    const { tags, severity = "error", statusCode } = options;
    const tagsMutated = { ...tags };

    if (statusCode) {
      tagsMutated.statusCode = statusCode;
      tagsMutated.isStatusCoded = true;
    }

    // https://docs.sentry.io/platforms/javascript/usage/set-level/
    sentry.withScope((scope) => {
      scope.setLevel(severity);
      sentry.captureException(error, { tags: tagsMutated });
    });
  },
};

const LocalErrorProviderImplementation: ErrorImplementation = {
  implementation: (sentry, error, options = {}) => {
    switch (options.severity || "error") {
      case "error":
      case "fatal":
        // eslint-disable-next-line no-console
        console.error("CAPTURED ERROR", options.severity, error, options);
        break;
      case "warning":
        // eslint-disable-next-line no-console
        console.warn("CAPTURED ERROR", options.severity, error, options);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log("CAPTURED ERROR", options.severity, error, options);
        break;
    }
  },
};

const ERROR_PROVIDER_IMPLEMENTATION: Record<ErrorHandlerProvider, ErrorImplementation> = {
  SENTRY: SentryErrorProviderImplementation,
  UNKNOWN: LocalErrorProviderImplementation,
};

export const rawCaptureError =
  ERROR_PROVIDER_IMPLEMENTATION[process.env.NODE_ENV === "development" ? "UNKNOWN" : DEFAULT_ERROR_HANDLER_PROVIDER]
    .implementation;

export type UseCaptureErrorOptions = {
  debounceSameErrorMs?: number;
};

/**
 * Map of recently captured errors and when they were captured (unix time)
 */
const capturedErrorMapAtom = atom<Map<unknown, number>>(new Map());

/**
 * To try and group `Error` objects that aren't memoized we hash them
 * @param error An `Error`
 * @returns A hash
 */
const hashError = (error: Error) => `${error.constructor.name}|${error.message}`;

/**
 * Hook for capturing errors
 * @param options
 * @returns A function to capture errors
 */
export const useCaptureError = (options: UseCaptureErrorOptions = {}) => {
  const { debounceSameErrorMs = MILLISECONDS_PER_HOUR } = options;

  const {
    state: { sentry },
  } = useAnalyticsContext();

  // We don't want to manage this as updatable state.  We
  // want to make changes in it without triggering
  // re-renders.  Since `Map` objects are PBR this is no
  // biggie.
  const capturedErrorMap = useAtomValue(capturedErrorMapAtom);

  const captureError = useCallbackSafeRef((error, { tags, severity, statusCode }: CaptureErrorOptions = {}) => {
    const ms = new Date().getTime();
    const errorKey = error instanceof Error ? hashError(error) : error;

    Array.from(capturedErrorMap.keys()).forEach((key) => {
      const capturedErrorMs = capturedErrorMap.get(key);
      if (capturedErrorMs && ms - capturedErrorMs > debounceSameErrorMs) capturedErrorMap.delete(key);
    });

    const capturedErrorMs = capturedErrorMap.get(errorKey);

    if (!capturedErrorMs) {
      capturedErrorMap.set(errorKey, ms);

      rawCaptureError(sentry, error, {
        tags,
        severity,
        statusCode,
      });
    }
  });

  return {
    captureError,
  };
};
