import { Annotation } from "./client";
import {
  ClickUpIntegrationDetails,
  IntegrationsEnabledMap,
  TimeSchemeIntegrationRule,
  TimeSchemeIntegrationRuleAction,
  TimeSchemeIntegrationRuleCreateRequest,
  TimeSchemeIntegrationRuleReindexRequest,
  TimeSchemeIntegrationRuleServiceType,
  TimeSchemeIntegrationRuleType,
  TimeSchemeIntegrationRuleUpdateRequest,
  TodoistIntegrationDetails,
  TodoistProject,
  ClickUpResource,
  ClickUpSpaceWithEligibility,
} from "./Integrations.types";
import {
  TimeSchemeIntegrationRule as TimeSchemeIntegrationRuleDto,
  TimeSchemeIntegrationRuleAction as TimeSchemeIntegrationRuleActionDto,
  TimeSchemeIntegrationRuleServiceType as TimeSchemeIntegrationRuleServiceTypeDto,
  TimeSchemeIntegrationRuleType as TimeSchemeIntegrationRuleTypeDto,
  TimeSchemeIntegrationRuleCreateRequest as TimeSchemeIntegrationRuleCreateRequestDto,
  TimeSchemeIntegrationRuleUpdateRequest as TimeSchemeIntegrationRuleUpdateRequestDto,
  TimeSchemeIntegrationRuleReindexRequest as TimeSchemeIntegrationRuleReindexRequestDto,
  TodoistIntegrationDetails as TodoistIntegrationDetailsDto,
  ClickUpIntegrationDetails as ClickUpIntegrationDetailsDto,
  TodoistProject as TodoistProjectDto,
  ClickUpResource as ClickUpResourceDto,
  ClickUpSpaceWithEligibility as ClickUpSpaceWithEligibilityDto,
} from "./client";

// incoming object doesn't include future
// integrations.  To be sure everything gets
// a boolean we're filling everything with a
// false.
const DEFAULT_ENABLED_MAP: Readonly<IntegrationsEnabledMap> = {
  ADD_ON: false,
  ASANA: false,
  CLICKUP: false,
  GOOGLE_TASKS: false,
  JIRA: false,
  LINEAR: false,
  RAYCAST: false,
  MONDAY: false,
  OFFICE365: false,
  SLACK: false,
  TODOIST: false,
  TRELLO: false,
  ZOOM: false,
};

export const dtoToIntegrationsEnabledMap = (dto: Readonly<Annotation>): Readonly<IntegrationsEnabledMap> => ({
  ...DEFAULT_ENABLED_MAP,
  ...dto,
});

// Todoist / Clickup Rules

export const dtoToIntegrationRuleAction = (dto: TimeSchemeIntegrationRuleActionDto) =>
  dto as TimeSchemeIntegrationRuleAction;
export const integrationRuleActionToDto = (action: TimeSchemeIntegrationRuleAction) =>
  action as TimeSchemeIntegrationRuleActionDto;

export const dtoToIntegrationRuleServiceType = (dto: TimeSchemeIntegrationRuleServiceTypeDto) =>
  dto as TimeSchemeIntegrationRuleServiceType;
export const integrationRuleServiceTypeToDto = (serviceType: TimeSchemeIntegrationRuleServiceType) =>
  serviceType as TimeSchemeIntegrationRuleServiceTypeDto;

export const dtoToIntegrationRuleType = (dto: TimeSchemeIntegrationRuleTypeDto) => dto as TimeSchemeIntegrationRuleType;
export const integrationRuleTypeToDto = (ruleType: TimeSchemeIntegrationRuleType) =>
  ruleType as TimeSchemeIntegrationRuleTypeDto;

export const dtoToIntegrationRule = (dto: TimeSchemeIntegrationRuleDto): TimeSchemeIntegrationRule => ({
  ...dto,
  labels: !!dto.labels ? dto.labels : undefined,
  projectIds: !!dto.projectIds ? dto.projectIds : undefined,
  action: dtoToIntegrationRuleAction(dto.action),
  serviceType: dtoToIntegrationRuleServiceType(dto.serviceType),
  ruleType: dtoToIntegrationRuleType(dto.ruleType),
  timeSchemeId: !!dto.timeSchemeId ? dto.timeSchemeId : undefined,
});

export const integrationRuleToDto = (rule: TimeSchemeIntegrationRule): TimeSchemeIntegrationRuleDto => ({
  ...rule,
  labels: !!rule.labels?.length ? rule.labels : null,
  projectIds: !!rule.projectIds?.length ? rule.projectIds : null,
  action: integrationRuleActionToDto(rule.action),
  serviceType: integrationRuleServiceTypeToDto(rule.serviceType),
  ruleType: integrationRuleTypeToDto(rule.ruleType),
});

export const integrationRuleCreateRequestToDto = (
  request: TimeSchemeIntegrationRuleCreateRequest
): TimeSchemeIntegrationRuleCreateRequestDto => ({
  ...request,
  labels: !!request.labels?.length ? request.labels : [],
  projectIds: !!request.projectIds?.length ? request.projectIds : [],
  action: integrationRuleActionToDto(request.action),
  serviceType: integrationRuleServiceTypeToDto(request.serviceType),
  ruleType: integrationRuleTypeToDto(request.ruleType),
});

export const integrationRuleToIntegrationRuleUpdateRequest = (
  rule: TimeSchemeIntegrationRule
): TimeSchemeIntegrationRuleUpdateRequest => ({
  labels: rule.labels,
  projectIds: rule.projectIds,
  action: rule.action,
  timeSchemeId: rule.timeSchemeId,
});

export const integrationRuleUpdateRequestToDto = (
  request: TimeSchemeIntegrationRuleUpdateRequest
): TimeSchemeIntegrationRuleUpdateRequestDto => ({
  ...request,
  labels: request.labels?.length !== 0 ? request.labels : [],
  // If the projectIds contain All only send ROOT instead of all project Ids
  projectIds: request.projectIds?.includes("ROOT") ? ["ROOT"] : request.projectIds,
  action: request.action as TimeSchemeIntegrationRuleActionDto,
});

export const integrationRuleReindexRequestToDto = (
  request: TimeSchemeIntegrationRuleReindexRequest
): TimeSchemeIntegrationRuleReindexRequestDto => ({
  ...request,
  serviceType: integrationRuleServiceTypeToDto(request.serviceType),
});

// Todoist

export const dtoToTodoistProject = (dto: TodoistProjectDto) =>
  ({
    id: dto.id as string,
    name: dto.name as string,
    // give all Todoist Projects a fake isEligible true to make consistent with ClickUp
    isEligible: true,
  }) as TodoistProject;

export const dtoToTodoistIntegrationDetails = (dto: TodoistIntegrationDetailsDto): TodoistIntegrationDetails => ({
  id: dto.id as string,
  availableProjects: !!dto.availableProjects?.length ? dto.availableProjects?.map(dtoToTodoistProject) : [],
  labelNames: !!dto.labelNames?.length ? dto.labelNames?.map((label) => label as string) : [],
  lastSyncedAt: dto.lastSyncedAt as string,
});

// Clickup

export const dtoToClickUpWorkspace = (dto: ClickUpResourceDto) => dto as ClickUpResource;

export const dtoToClickUpSpaceWithEligibility = (dto: ClickUpSpaceWithEligibilityDto) =>
  dto as ClickUpSpaceWithEligibility;

export const dtoToClickUpIntegrationDetails = (dto: ClickUpIntegrationDetailsDto): ClickUpIntegrationDetails => ({
  workspace: dto.workspace
    ? dtoToClickUpWorkspace(dto.workspace)
    : {
        id: "",
        name: "",
      },
  availableSpaces: !!dto.availableSpaces?.length ? dto.availableSpaces?.map(dtoToClickUpSpaceWithEligibility) : [],
  tags: !!dto.tags?.length ? dto.tags.map((tag) => tag as string) : [],
  lastSynced: dto.lastSynced as string,
});
