import { connectedAccountToDto, dtoToConnectedAccount } from "./Accounts.mutators";
import { ConnectedAccount } from "./Accounts.types";
import { ConnectedAccount as ConnectedAccountDto } from "./client";
import { TransformDomain } from "./types";

export class AccountsDomain extends TransformDomain<ConnectedAccount, ConnectedAccountDto> {
  resource = "ConnectedAccount";
  cacheKey = "connectedAccount";
  pk = "id";

  public serialize = connectedAccountToDto;
  public deserialize = dtoToConnectedAccount;

  list = this.typedManageErrors(async () => (await this.api.accounts.list()).map(dtoToConnectedAccount));

  delete = this.typedManageErrors((id: number) => this.api.accounts.delete(id));

  authRedirect = (baseUri: string, state: Record<string, unknown> = {}) => {
    const authUrl = new URL(baseUri, window.location.href);
    authUrl.searchParams.append("state", JSON.stringify(state));
    window.location.href = authUrl.toString();
  };

  getCalendars = (accountId: number) => this.api.accounts.getCalendars(accountId);

  setAvailability = (accountId: number, calendarIds: string[]) =>
    this.api.accounts.setAvailability(accountId, calendarIds);
}
