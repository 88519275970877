import { Box, CircularProgress, CircularProgressProps, ClassNameMap } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Override } from "@typeDefs/index";
import clsx from "clsx";
import { VFC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      align: "center",
      verticalAlign: "middle",
    },
    spinner: {},
    circle: {
      strokeLinecap: "round",
    },
  }),
  { classNamePrefix: "LoadingIcon" }
);
export type LoadingIconJSSClassKey = keyof ReturnType<typeof useStyles>;

export type LoadingIconProps = Override<CircularProgressProps, {
  classes?: Partial<ClassNameMap<LoadingIconJSSClassKey>>;
}>;

export const LoadingIcon: VFC<LoadingIconProps> = ({ className, classes: extClasses, size = 18, ...rest }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Box className={clsx(classes.root, className)}>
      <CircularProgress size={size} className={classes.spinner} classes={{ circle: classes.circle }} {...rest} />
    </Box>
  );
};
