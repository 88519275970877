import { Resources as ResourcesDto } from "./client";
import { dtoToHabit } from "./Habits.mutators";
import { dtoToOneOnOne } from "./OneOnOnes.mutators";
import { Resources } from "./Resources.types";
import { dtoToSchedulingLink } from "./scheduling-links/SchedulingLinks";
import { dtoToTask } from "./Tasks";

export const dtoToResources = (dto: ResourcesDto): Resources => ({
  tasks: dto.taskList.map(dtoToTask),
  habits: dto.habitList.map(dtoToHabit),
  oneOnOnes: dto.oneOnOneList.map(dtoToOneOnOne),
  schedulingLinks: dto.schedulingLinkList.map(dtoToSchedulingLink),
});
